/* Built In Imports */
// import NextLink from 'next/link';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
// import CardInfoWithMultipleImageDetail from '@components/Card/CardInfoWithMultipleImageDetail';
/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the Card Content Zigzag 2 Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - Language for the section
 * @returns {ReactElement} Card Content Zigzag Card component
 */

const LivingIshaCardContent = ({ sectionContent, region, lang }) => {
  let lyrDirection = '';
  {
    sectionContent.cardId === 'Visual_right'
      ? (lyrDirection = 'row-reverse')
      : (lyrDirection = 'row');
  }
  return (
    <Box m="1vw 0" w={'100%'}>
      {sectionContent.cardId === 'upcoming-programs-delhi' ? (
        <Box
          width={'100%'}
          h="100%"
          m="0 auto"
          pl={'10px'}
          pr={'10px'}
          className="block-content"
        >
          <Box
            textAlign={'center'}
            m="0 auto"
            width={{base:'96%', lg:'948px'}}
          >
            <LazyLoadImageComponent
              alt={sectionContent?.image?.alt}
              title={sectionContent?.image?.title}
              width={{ base: '100%', md: '100%' }}
              src={sectionContent?.image?.url}
            />
            <StructuredTextParser
              str={render(sectionContent.titleBody)}
              region={region}
              lang={lang}
              className='event-text'
            />
          </Box>
      </Box>
      ) :  (
      <Box
        width={'90%'}
        h="100%"
        m="0 auto"
        pl={'10vw'}
        pr={'10vw'}
        className="cardcontent"
      >
        <Box
          textAlign={'center'}
          display={{ sm: 'block', md: 'flex', lg: 'flex' }}
          flexDirection={lyrDirection}
        >
          <Box p="2.2vw">
            <LazyLoadImageComponent
              alt={sectionContent?.image?.alt}
              title={sectionContent?.image?.title}
              width={{ base: '100%', md: '100%' }}
              src={sectionContent?.image?.url}
            />
          </Box>
          <Box>
            <StructuredTextParser
              str={render(sectionContent.titleBody)}
              region={region}
              lang={lang}
            />
          </Box>
        </Box>
      </Box>
    )}
      <style global jsx>
        {`
          .block-content .event-text p {
            padding: 6px 0;
            font-size: 18px !important;
            line-height: 1.2;
          }
          .block-content .event-text p strong {
            color: #12166F;
          }
          .cardcontent ul {
            padding-left: 1.5em;
          }
          .cardcontent li {
            padding: 0.6vw;
            line-height: 1.3;
          }
          .cardcontent ul,
          .cardcontent li p {
            font-family: 'FedraSansStd-book';
            font-size: 18px;
          }
        `}
      </style>
    </Box>
  );
};

export default LivingIshaCardContent;
