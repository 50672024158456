/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import SectionTitle from '@components/Headings/SectionTitle';
import LivingIshaCardContent from './LivingIshaCardContent';

/* Services */

/**
 * Renders the Living Isha Section component
 *
 * @param {object} sectionContent - Data for section
 * @param sectionContent.section
 * @param {string} region - Region for the Section
 * @param {string} lang - Language for the Section 
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} - Living Isha Section Component
 */
const LivingIsha = ({ section, region, lang }) => {
  return (
    <Box as="div" display="block" placeContent="center" my={10}>
      <Box>
        <SectionTitle titleObj={section.sectionContent[0]} />
      </Box>
      <Box>
        <LivingIshaCardContent
          sectionContent={section.sectionContent[1]}
          region={region}
          lang={lang}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Box display="inline-block" m="0 40px">
          <SimpleButton
            style={section.sectionContent[2]}
            region={region}
            lang={lang}
          />
        </Box>
        <Box display="inline-block" m="0 40px">
          <SimpleButton
            style={section.sectionContent[3]}
            region={region}
            lang={lang}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LivingIsha;
